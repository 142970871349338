import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from "./Footer";
import Header from "./Header";
import aboutImg from "./assets/img/aboutImg.png";
import Img1 from "./assets/img/clients/img-1.png";
import Img2 from "./assets/img/clients/img-2.png";
import Img3 from "./assets/img/clients/img-3.png";
import Img4 from "./assets/img/clients/img-4.png";
import Img5 from "./assets/img/clients/img-5.png";
import Img6 from "./assets/img/clients/img-6.png";
import Img7 from "./assets/img/clients/img-7.png";
import Img8 from "./assets/img/clients/img-8.png";
import Img9 from "./assets/img/clients/img-9.png";
import Img10 from "./assets/img/clients/img-10.png";
import Img11 from "./assets/img/clients/img-11.png";
import Img12 from "./assets/img/clients/img-12.png";
import Img13 from "./assets/img/clients/img-13.png";
import NoUser from "./assets/img/noUser.png";
import AllanDas from "./assets/img/allanDas.png";
import RSilambarasan from "./assets/img/Silambarasan.png";
import MukeshBhasker from "./assets/img/MukeshBhasker.png";
const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>
          Hummingtec: Pioneering Technology Solutions in Healthcare and Beyond
        </title>
        <meta
          name="description"
          content="Discover Hummingtec's journey from a startup in 2016 to a leader in healthcare technology and software solutions. Explore our vision for a future where technology and human ingenuity merge to drive innovation and efficiency."
        />
        <meta
          name="keywords"
          content="Hummingtec, healthcare technology, software development, Pillnurse, digital transformation, AI and ML, blockchain, IoT, CRM solutions, Zoho, Salesforce, medication adherence, technology innovation"
        />
        <link rel="canonical" href="https://www.hummingtec.com/AboutUs" />
      </Helmet>
      <Header></Header>
      <div className="mainContent">
        <div className="bannerWrap innerBanner">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9">
                <div className="titleWrap">
                  <h2>About Us</h2>
                  <p>Building the future...</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="aboutSectionWrap padBtm">
          <div class="container">
            <div class="row">
              <div class="col-lg-6">
                <div class="imgWrap">
                  <img src={aboutImg} class="img-fluid" alt="" />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="dataWrap">
                  <h2>Our Journey</h2>
                  <p>
                    Hummingtec, founded in 2016, embarked on a journey fueled by
                    a steadfast commitment to leveraging technology for
                    transformative solutions in healthcare and beyond. The
                    company's inception stemmed from a passion for merging
                    technological innovation with the imperative to enhance
                    people's lives.
                  </p>
                  <p>
                    From its outset, Hummingtec embarked on a dual mission: to
                    pioneer groundbreaking advancements in healthcare through
                    Pillnurse, a pioneering initiative aimed at revolutionizing
                    medication adherence, while concurrently establishing a
                    robust Software Development and Services arm to catalyze
                    technological evolution across diverse industries.
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-6">
                <div class="dataWrap">
                  <h2 className="mrtBtms">Vision</h2>
                  <p>
                    Hummingtec aspires to be at the forefront of technological
                    advancements, pioneering next-generation technologies that
                    revolutionize industries and shape the future of innovation.
                  </p>
                  <p>
                    At Humingtec, we envision a world where technology
                    seamlessly integrates with human ingenuity to drive
                    innovation, efficiency, and growth. Our vision is to be the
                    leading catalyst for transformative software solutions and
                    services that empower businesses and individuals to realize
                    their full potential in the digital age. We strive to create
                    cutting-edge technologies that inspire creativity, foster
                    collaboration, and enhance the way people live, work, and
                    connect globally."
                  </p>
                  <h2 className="mrtBtms">Mission</h2>
                  <p>
                    At Hummingtec, our mission is not just about delivering
                    software solutions—it's about making a difference. Together,
                    we innovate, collaborate, and inspire, shaping a world where
                    possibilities are limitless and success knows no bounds."
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="timelineWrap">
                  <ul>
                    <li>
                      <div className="itemWrap">
                        <h2>May 2016</h2>
                        <div className="dataWrap">
                          <p>
                            Hummingtec Starts from a small Idea to improve
                            medication Adherence
                          </p>
                          <h3>
                            Number of Countries served - <span>3</span>
                          </h3>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="itemWrap">
                        <h2>2019</h2>
                        <div className="dataWrap">
                          <p>
                            Digital Marketing and IOT Services added to the
                            Offerings
                          </p>
                          <h3>
                            Number of Countries served - <span>6</span>
                          </h3>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="itemWrap">
                        <h2>2022</h2>
                        <div className="dataWrap">
                          <p>
                            AI & ML, Blockchain Capabilities - added to the
                            Service offerings
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="itemWrap">
                        <h2>2023</h2>
                        <div className="dataWrap">
                          <p>CRM - Zoho and Salesforce Capabilities</p>
                          <h3>
                            Number of Countries served - <span>13</span>
                          </h3>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="teamDataWrap">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="titleWrap">
                  <h2>Team</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="dataWrap">
                  <p>
                    Welcome to Hummingtec! Get to know the faces behind our
                    company. Our leadership team isn't just about titles;
                    they're the heartbeat of Hummingtec, driving our vision
                    forward with passion and dedication
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="itemWrap">
                  <div className="imgWrap">
                    <img src={AllanDas} className="img-fluid" alt="" />
                  </div>
                  <div className="dataWrap">
                    <h2>Allan Das</h2>
                    <h4>Founder/CEO</h4>
                    <p>
                      The driving force behind Hummingtec's inception.His
                      journey started with a vision and a mission to
                      revolutionize tech solutions.
                    </p>
                    <p>
                      He is not just about strategy; they're hands-on, inspiring
                      our team every day to reach greater heights.
                    </p>
                  </div>
                  <div className="socialWrap">
                    <ul>
                      <li>
                        <Link
                          to={
                            "https://www.linkedin.com/in/allan-das/?originalSubdomain=in"
                          }
                          target="_blank"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            width="14"
                            height="14"
                            x="0"
                            y="0"
                            viewBox="0 0 100 100"
                          >
                            <g>
                              <path
                                d="M90 90V60.7c0-14.4-3.1-25.4-19.9-25.4-8.1 0-13.5 4.4-15.7 8.6h-.2v-7.3H38.3V90h16.6V63.5c0-7 1.3-13.7 9.9-13.7 8.5 0 8.6 7.9 8.6 14.1v26H90zM11.3 36.6h16.6V90H11.3zM19.6 10c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.7 9.6 9.7 9.6-4.4 9.6-9.7-4.3-9.6-9.6-9.6z"
                                fill="#000000"
                                opacity="1"
                                data-original="#000000"
                              ></path>
                            </g>
                          </svg>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="itemWrap">
                  <div className="imgWrap">
                    <img src={RSilambarasan} className="img-fluid" alt="" />
                  </div>
                  <div className="dataWrap">
                    <h2>Mr. R. Silambarasan</h2>
                    <h4>Chief Operating Officer (COO)</h4>
                    <p>
                      Mr. R. Silambarasan ensures that our day-to-day operations
                      run smoothly. With a knack for efficiency, he keeps
                      Hummingtec humming along.
                    </p>
                    <p>
                      His leadership keeps our ship steady, navigating
                      challenges with grace and innovation.
                    </p>
                  </div>
                  <div className="socialWrap">
                    <ul>
                      <li>
                        <a href="#">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            width="14"
                            height="14"
                            x="0"
                            y="0"
                            viewBox="0 0 100 100"
                          >
                            <g>
                              <path
                                d="M90 90V60.7c0-14.4-3.1-25.4-19.9-25.4-8.1 0-13.5 4.4-15.7 8.6h-.2v-7.3H38.3V90h16.6V63.5c0-7 1.3-13.7 9.9-13.7 8.5 0 8.6 7.9 8.6 14.1v26H90zM11.3 36.6h16.6V90H11.3zM19.6 10c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.7 9.6 9.7 9.6-4.4 9.6-9.7-4.3-9.6-9.6-9.6z"
                                fill="#000000"
                                opacity="1"
                                data-original="#000000"
                              ></path>
                            </g>
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="itemWrap">
                  <div className="imgWrap">
                    <img src={NoUser} className="img-fluid" alt="" />
                  </div>
                  <div className="dataWrap">
                    <h2>Mr. Vishak Nair</h2>
                    <h4>Chief Technology Officer (CTO)</h4>
                    <p>
                      Our tech guru, Mr. Vishak Nair. They're the brains behind
                      our cutting-edge solutions, always pushing boundaries and
                      embracing new ideas.
                    </p>
                    <p>
                      With a passion for innovation, they keep us ahead of the
                      curve, driving our products to new heights of excellence.
                    </p>
                  </div>
                  <div className="socialWrap">
                    <ul>
                      <li>
                        <a href="#">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            width="14"
                            height="14"
                            x="0"
                            y="0"
                            viewBox="0 0 100 100"
                          >
                            <g>
                              <path
                                d="M90 90V60.7c0-14.4-3.1-25.4-19.9-25.4-8.1 0-13.5 4.4-15.7 8.6h-.2v-7.3H38.3V90h16.6V63.5c0-7 1.3-13.7 9.9-13.7 8.5 0 8.6 7.9 8.6 14.1v26H90zM11.3 36.6h16.6V90H11.3zM19.6 10c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.7 9.6 9.7 9.6-4.4 9.6-9.7-4.3-9.6-9.6-9.6z"
                                fill="#000000"
                                opacity="1"
                                data-original="#000000"
                              ></path>
                            </g>
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="itemWrap">
                  <div className="imgWrap">
                    <img src={MukeshBhasker} className="img-fluid" alt="" />
                  </div>
                  <div className="dataWrap">
                    <h2>Mukesh Bhasker</h2>
                    <h4>Head of Operations - United Kingdom</h4>
                    <p>
                      Mukesh Bhasker leads our operations in the UK with
                      precision and expertise. His strategic approach ensures
                      seamless execution, driving efficiency and growth in the
                      region. With a strong operational mindset, he keeps
                      everything running smoothly.
                    </p>
                  </div>
                  <div className="socialWrap">
                    <ul>
                      <li>
                        <Link to={""} target="_blank">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            width="14"
                            height="14"
                            x="0"
                            y="0"
                            viewBox="0 0 100 100"
                          >
                            <g>
                              <path
                                d="M90 90V60.7c0-14.4-3.1-25.4-19.9-25.4-8.1 0-13.5 4.4-15.7 8.6h-.2v-7.3H38.3V90h16.6V63.5c0-7 1.3-13.7 9.9-13.7 8.5 0 8.6 7.9 8.6 14.1v26H90zM11.3 36.6h16.6V90H11.3zM19.6 10c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.7 9.6 9.7 9.6-4.4 9.6-9.7-4.3-9.6-9.6-9.6z"
                                fill="#000000"
                                opacity="1"
                                data-original="#000000"
                              ></path>
                            </g>
                          </svg>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="dataWrap martTop">
                  <p>
                    At Hummingtec, our leadership isn't just about titles; it's
                    about people who believe in our mission and drive us forward
                    every day. Get to know them, and you'll see the passion and
                    dedication that make Hummingtec more than just a
                    company—we're a community, innovating together for a
                    brighter future.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="ourJourneyLsitWrap">
                    <div class="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div class="dataWrap">
                                    <p>Underpinning Hummingtec's ethos is a relentless pursuit of excellence, exemplified by the development of a diverse portfolio of high-technology solutions designed to address multifaceted challenges:</p>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-6">
                                <div class="itemWrap bgClr1">
                                    <p>Spearheading the creation of an IoT-enabled solution for managing E-Bikes, Hummingtec empowered Star Engineers with a sophisticated mobile interface capable of controlling ignition, implementing geofencing, and tracing routes, thereby ushering in a new era of efficiency and control.</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="itemWrap bgClr2">
                                    <p>In collaboration with China Railway, Hummingtec engineered a customized CRM system to automate the intricate processes underlying the construction of railway projects spanning Tanzania, Mozambique, and Kenya. This endeavor epitomized Hummingtec's capacity to deliver tailored solutions that optimize workflow and maximize productivity on a monumental scale.</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="itemWrap bgClr3">
                                    <p>The development of Symbio, an EMS solution for an esteemed American healthcare organization, showcased Hummingtec's prowess in architecting sophisticated systems enriched with AI-driven patient rerouting capabilities. This innovative platform exemplified Hummingtec's unwavering commitment to redefining patient care through cutting-edge technology.</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="itemWrap bgClr4">
                                    <p>Uburu, a cloud-based software platform designed to facilitate the management of patients with cognitive disabilities, epitomized Hummingtec's dedication to fostering inclusivity and empowerment. By leveraging interactive games and performance analytics, Uburu enabled users to track and enhance cognitive capabilities, thereby fostering independence and wellbeing.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="dataWrap">
                                    <p>Throughout its journey, Hummingtec has remained steadfast in its pursuit of excellence, continuously striving to push the boundaries of technological innovation while making meaningful contributions to healthcare and beyond. As the company continues to evolve, its unwavering commitment to leveraging technology for positive societal impact remains its guiding principle.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
        <div className="ourClients">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="titleWrap">
                  <h2>Our Clients</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ul>
                  {/* <li>
                                        <a href="#">
                                            <img src={Img1} class="img-fluid" alt="" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src={Img2} class="img-fluid" alt="" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src={Img3} class="img-fluid" alt="" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src={Img4} class="img-fluid" alt="" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src={Img5} class="img-fluid" alt="" />
                                        </a>
                                    </li> */}
                  <li>
                    <a href="#">
                      <img src={Img6} class="img-fluid" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={Img7} class="img-fluid" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={Img8} class="img-fluid" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={Img9} class="img-fluid" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={Img10} class="img-fluid" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={Img11} class="img-fluid" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={Img12} class="img-fluid" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={Img13} class="img-fluid" alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};
export default AboutUs;
